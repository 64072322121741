<template>
  <el-row>
    <el-row :md="6" :sm="12" :xs="12">
      <el-table
        :data="dataList"
        style="width: 100%"
      >
        <el-table-column
          type="index"
        />
        <el-table-column
          prop="createAt"
          label="发布时间"
        />
        <el-table-column
          prop="title"
          label="文章标题"
        >
          <template slot-scope="scope">
            <router-link target="_blank" :to="`/article/${scope.row.articleId}`">
              <span>{{ scope.row.title }}</span>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="scope"
          label="可见范围"
        >
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="点击修改可见范围" placement="top-end">
              <el-button
                v-if="scope.row.scope === 1"
                size="mini"
                @click="handleScope(scope.$index, scope.row)"
              >本人可见</el-button>
              <el-button
                v-else-if="scope.row.scope === 2"
                size="mini"
                type="success"
                @click="handleScope(scope.$index, scope.row)"
              >所有人可见</el-button>
              <el-button
                v-else-if="scope.row.scope === 3"
                size="mini"
                type="warning"
                @click="handleScope(scope.$index, scope.row)"
              >VIP 可见</el-button>
              <el-button
                v-else
                size="mini"
                type="danger"
                @click="handleScope(scope.$index, scope.row)"
              >验证码可见</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 修改可见范围对话框 -->
      <el-dialog
        append-to-body
        :visible.sync="showEditScopeDialog"
        width="30%"
        center
      >
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>修改可见范围</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onUpdateScope">更新</el-button>
          </div>
          <div class="text item">
            <el-select v-model="form.scope" placeholder="选择可见范围">
              <el-option label="本人可见" value="1" />
              <el-option label="所有人可见" value="2" />
              <el-option label="VIP 可见" value="3" />
              <el-option label="验证码可见" value="4" />
            </el-select>
          </div>
        </el-card>
      </el-dialog>
    </el-row>
    <el-row>
      <el-pagination
        background
        :small="screenWidth <= 768"
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="totalSize"
        @current-change="handleCurrentChange"
        @prev-click="handleCurrentChange"
        @next-click="handleCurrentChange"
      />
    </el-row>
  </el-row>
</template>

<script>
import { updateArticleScope, deleteArticle, getArticlePosts } from '@/api/article'

export default {
  name: 'ArticlePost',
  data() {
    return {
      // 屏幕宽度, 为了控制分页条的大小
      screenWidth: document.body.clientWidth,
      currentPage: 1,
      pageSize: 12,
      totalSize: 0,
      dataList: [],
      // **********************************************************************
      showEditScopeDialog: false,
      form: {
        articleId: null,
        scope: 1
      }
    }
  },
  created() {
    document.title = '文章稿件'
    this.getData()
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.currentPage = pageNumber
      this.getData()
      // 回到顶部
      scrollTo(0, 0)
    },
    getData() {
      getArticlePosts(this.currentPage).then(resp => {
        if (resp.code === 0) {
          this.dataList = resp.data.list
          this.totalSize = resp.data.totalSize
        } else {
          this.$notify({
            title: '提示',
            message: resp.msg,
            type: 'warning',
            duration: 3000
          })
        }
      })
    },
    handleScope(index, row) {
      this.form.articleId = row.articleId
      this.form.scope = '' + row.scope
      this.showEditScopeDialog = true
    },
    handleEdit(index, row) {
      const path = '/post/edit/article/' + row.articleId
      this.$router.push(path)
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除 ' + row.title + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteArticle(row.articleId).then(res => {
          if (res.code === 0) {
            this.$notify({
              title: '提示',
              message: '稿件已删除',
              type: 'warning',
              duration: 3000
            })
            // this.$router.go(0)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    onUpdateScope() {
      this.showEditScopeDialog = false
      updateArticleScope(this.form).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '可见范围已更新',
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
</style>
