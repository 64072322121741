import { delete0, get, post } from '@/utils/request'

const articleApi = {
  articlePostApi: '/api/content/post/article',
  updateArticleScopeApi: '/api/content/post/article/update/scope',
  updateArticleContentApi: '/api/content/post/article/update/content',
  articleApi: '/api/content/article',
  userArticleApi: '/api/content/article/user'
}

export function submitArticle(data) {
  return post(articleApi.articlePostApi, data)
}

export function updateArticleScope(jsonData) {
  return post(articleApi.updateArticleScopeApi, jsonData)
}

export function updateArticleContent(jsonData) {
  return post(articleApi.updateArticleContentApi, jsonData)
}

export function deleteArticle(videoId) {
  return delete0(articleApi.articleApi + '/' + videoId)
}

export function getArticlePosts(page) {
  return get(articleApi.articlePostApi + '?page=' + page)
}

export function getArticlePost(articleId) {
  return get(articleApi.articlePostApi + '/' + articleId)
}

export function getArticles(page) {
  return get(articleApi.articleApi + '?page=' + page)
}

export function getUserArticles(userId, page) {
  return get(articleApi.userArticleApi + '?userId=' + userId + '&page=' + page)
}

export function getArticle(articleId) {
  return get(articleApi.articleApi + '/' + articleId)
}
